export interface IFieldValue {
    active: boolean;
    field_id: number;
    id: number;
    name: string;
}

export interface IField {
    active: boolean;
    category_id: number;
    id: number;
    name: string;
    required: boolean;
    type: "number" | "integer" | "boolean" | "string" | "value";
    value?: any;
    field_value_array?: IFieldValue[];
}

export enum FieldActionTypes {
    FETCH = "FETCH_FIELD",
    FETCH_SUCCESS = "FETCH_FIELD_SUCCESS",
    FETCH_ERROR = "FETCH_FIELD_ERROR",
}

export interface IFieldFetchAction {
    type: FieldActionTypes.FETCH;
}

export interface IFieldFetchSuccessAction {
    type: FieldActionTypes.FETCH_SUCCESS;
    payload: IField[];
}

export interface IFieldFetchErrorAction {
    type: FieldActionTypes.FETCH_ERROR;
    payload: string;
}

export type FieldActionType = IFieldFetchAction | IFieldFetchSuccessAction | IFieldFetchErrorAction;

export interface IFieldListState {
    list: null | IField[];
    listByCategoryId: null | { [key: number]: IField[] };
    loading: boolean;
    error: null | string;
}

const initialState: IFieldListState = {
    list: null,
    listByCategoryId: null,
    loading: false,
    error: null,
};

export const fieldsReducer = (state: IFieldListState = initialState, action: FieldActionType): IFieldListState => {
    switch (action.type) {
        case FieldActionTypes.FETCH:
            return { ...state, loading: true, error: null };
        case FieldActionTypes.FETCH_SUCCESS:
            let list: IField[] = action.payload;
            let listByCategoryId = (() => {
                let result: { [key: number]: IField[] } = {};

                list.forEach((field) => {
                    if (result[field.category_id]) {
                        result[field.category_id].push(field);
                    } else {
                        result[field.category_id] = [field];
                    }
                });

                return result;
            })();

            return { loading: false, error: null, list, listByCategoryId };
        case FieldActionTypes.FETCH_ERROR:
            return { loading: false, error: action.payload, list: null, listByCategoryId: null };
        default:
            return state;
    }
};
