import { ICustomer } from "./customerTypes";

export enum UserActionTypes {
    LOGIN = "LOGIN",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    LOGIN_ERROR = "LOGIN_ERROR",
    LOGOUT = "LOGOUT",
    REGISTER = "REGISTER",
    REGISTER_SUCCESS = "REGISTER_SUCCESS",
    REGISTER_PERSONAL_DATA_SUCCESS = "REGISTER_PERSONAL_DATA_SUCCESS",
    REGISTER_ERROR = "REGISTER_ERROR",
}

export enum UserRole {
    ADMIN = 1,
    CUSTOMER = 2,
    IN_REGISTRATION = 3,
    SERVICE = 4,
}

export enum UserSubjectType {
    FIZ = 1,
    YUR = 2,
    FOP = 3,
}

export interface IBalance {
    balance: number;
    customer_id: string;
}

export interface IUserRole {
    id: UserRole;
    name: string;
}

export interface IUser {
    uuid: string;
    username: string;
    email: string;
    phone_number: string;
    first_name: string;
    last_name: string;
    second_name: string;
    active: boolean;
    created_at: number;
    phone_number_confirmed: boolean;
    phone_number_confirmed_at: number;
    avatar_url: string;
    permissions: string[];
    reg_step_id: number | null;
    role: IUserRole;
    customer: ICustomer;
    // customer_id: number;
    customer_uuid: string;
    email_confirmed: boolean;
    email_confirmed_at: number;
    inn: string;
    subject_type: UserSubjectType;
}

export interface ILoginUserResponse {
    refresh_token: string;
    session_data: string;
    token: string;
}

export interface ILoginUserAction {
    type: UserActionTypes.LOGIN;
}

export interface ILoginUserSuccessAction {
    type: UserActionTypes.LOGIN_SUCCESS;
    payload: IUser;
}

export interface ILoginUserErrorAction {
    type: UserActionTypes.LOGIN_ERROR;
    payload: string;
}

export interface ILogoutUserAction {
    type: UserActionTypes.LOGOUT;
}

export interface IUserState {
    user: null | IUser;
    error: null | string;
    loading: boolean;
}

export type UserLoginAction = ILoginUserAction | ILoginUserSuccessAction | ILoginUserErrorAction | ILogoutUserAction;

export interface ILoginInputs {
    username: string;
    password: string;
}

export interface IRegisterUserAction {
    type: UserActionTypes.REGISTER;
}

export interface IRegisterUserSuccessAction {
    type: UserActionTypes.REGISTER_SUCCESS;
    payload: IUser;
}

export interface IRegisterPersonalDataSuccessAction {
    type: UserActionTypes.REGISTER_SUCCESS;
    payload: IUser;
}

export interface IRegisterUserErrorAction {
    type: UserActionTypes.REGISTER_ERROR;
    payload: string;
}

export type UserRegisterAction =
    | IRegisterUserAction
    | IRegisterPersonalDataSuccessAction
    | IRegisterUserSuccessAction
    | IRegisterUserErrorAction;

export interface IRegisterInputs {
    email: string;
    password: string;
}

export interface IRegisterPersonalDataInputs {
    phone_number: string;
    email: string;
}

export interface IRegisterCompanyDataInputs {
    name: string;
    inn: string;
    city_id: string;
}

export interface IRegisterUserResponse {
    refresh_token: string;
    session_data: string;
    token: string;
}

export interface IRegisterPersonalDataResponse {
    refresh_token: string;
    session_data: string;
    token: string;
}

export interface IRegisterCompanyDataResponse {
    user_data: IUser;
    customer_data: ICustomer;
}
