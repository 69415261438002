export const API_DOMAIN = process.env.API_PATH;
export const API_PATH = {
    CATEGORY_INDEX: "/category_index",
    CATEGORY_LIST: "/categories",
    CHANGE_PASSWORD: "/password",
    CONSENT_SIGN: "/consent_sign",
    CONTACT_US: "/contact_us",
    CONTRACT: "/contract",
    DEALS: "/deals",
    DEAL_CLAUSE_DRAFT: "/deal_clause",
    DEAL_CLAUSE_PUBLIC: "/replace_deal_clause",
    DEAL_CLAUSE_PUBLIC_DELETE: "/suggest_delete_deal_clause",
    DEAL_CLAUSE_ACCEPT_CHANGES: "/accept_changes_deal_clause",
    DEAL_CLAUSE_REJECT_CHANGES: "/cancel_changes_deal_clause",
    DEAL_CLAUSES_TEMPLATE: "/contract_template",
    DEAL_IMPORT_TEMPLATE: "/import_template_to_deal",
    DEAL_IMPORT_CONTRACT: "/import_contract_to_deal",
    DEAL_ITEM: "/deals/:uuid",
    DEPOSIT: "/deposit",
    DEPOSIT_SUM: "/deposit_sum",
    EXCHANGE_RATES: "/exchange_rates",
    USER_BALANCE: "/my_balance",
    FIELDS: "/fields",
    LOGIN: "/login",
    LOGIN_STEP_1: "/sign_login",
    LOTS: "/lots",
    LOT_CANCEL: "/cancel_lot",
    LOT_OFFERS: "/lot_offers",
    LOT_PUBLISH: "/lot_publish/:uuid",
    NOTIFICATIONS: "/notifications",
    OFFERS: "/offers",
    OFFERS_ACCEPT: "/accept_offer",
    OFFERS_REJECT: "/reject_offer",
    OFFER_CANCEL: "/cancel_offer/:uuid",
    OFFER_READ: "/read_offer/:uuid",
    OFFER_PUBLISH: "/offer_publish/:uuid",
    PROFILE_DEALS: "/my_deals",
    PROFILE_DEPOSITS: "/my_deposits",
    PROFILE_LOTS: "/my_lots",
    PROFILE_OFFERS: "/my_offers",
    READ_NOTIFICATION: "/read_notification",
    REFRESH_TOKEN: "/refresh",
    REGISTER_STEP_1: "/registration/1",
    REGISTER_STEP_2: "/registration/2",
    REGISTER_STEP_3: "/registration/3",
    TRANSACTION: "/transactions",
    TRANSACTION_INTERNAL: "/internal_transactions",
};
