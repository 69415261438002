import { SvgIcon } from "@material-ui/core";
import React from "react";
import { ReactComponent as BuyIcon } from "../assets/icons/icon_buy.svg";
import { ReactComponent as SellIcon } from "../assets/icons/icon_sell.svg";
import { LotType } from "../types/lotTypes";
import { ICurrencyList } from "../types/mainTypes";

export const SELL_BUY_ICON = {
    [LotType.SELL]: <SvgIcon component={SellIcon} />,
    [LotType.BUY]: <SvgIcon component={BuyIcon} />,
};
export const SELL_BUY_TEXT: any = {
    [LotType.SELL]: "Продаж",
    [LotType.BUY]: "Купівля",
};

export enum CURRENCY_ID {
    UAH = 1,
    USD = 2,
    EUR = 3,
}

export const CURRENCY: ICurrencyList = {
    UAH: {
        id: CURRENCY_ID.UAH,
        text: "грн",
        upperText: "UAH",
        symbol: "₴",
    },
    USD: {
        id: CURRENCY_ID.USD,
        text: "usd",
        upperText: "USD",
        symbol: "$",
    },
    EUR: {
        id: CURRENCY_ID.EUR,
        text: "euro",
        upperText: "EUR",
        symbol: "€",
    },
};
