import React from "react";
import "./Svg.scss";

const Svg = (props: any) => {
    let Component: any = props.i;

    return <Component className="custom-svg" />;
};

export default Svg;
