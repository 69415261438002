import { IRegion } from "../../../types/regionTypes";

export enum RegionActionTypes {
    FETCH = "FETCH_REGIONS",
    FETCH_SUCCESS = "FETCH_REGIONS_SUCCESS",
    FETCH_ERROR = "FETCH_REGIONS_ERROR",
}

export interface IRegionFetchAction {
    type: RegionActionTypes.FETCH;
}

export interface IRegionFetchSuccessAction {
    type: RegionActionTypes.FETCH_SUCCESS;
    payload: IRegion[];
}

export interface IRegionFetchErrorAction {
    type: RegionActionTypes.FETCH_ERROR;
    payload: string;
}

export type RegionActionType = IRegionFetchAction | IRegionFetchSuccessAction | IRegionFetchErrorAction;

export interface IRegionListState {
    list: null | IRegion[];
    loading: boolean;
    error: null | string;
}

const initialState: IRegionListState = {
    list: null,
    loading: false,
    error: null,
};

export const regionsReducer = (state: IRegionListState = initialState, action: RegionActionType): IRegionListState => {
    switch (action.type) {
        case RegionActionTypes.FETCH:
            return { ...state, loading: true, error: null };
        case RegionActionTypes.FETCH_SUCCESS:
            let list: IRegion[] = action.payload;

            return { loading: false, error: null, list };
        case RegionActionTypes.FETCH_ERROR:
            return { loading: false, error: action.payload, list: null };
        default:
            return state;
    }
};
