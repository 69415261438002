import axios from "axios";
import { API_DOMAIN, API_PATH } from "../constants/api";

export const fetchFieldList = () => {
    return axios.get(API_DOMAIN + API_PATH.FIELDS);
};

export const fetchCategoryFieldList = (category_id: string) => {
    return axios.get(API_DOMAIN + API_PATH.FIELDS, {
        headers: { "Content-Type": "text/plain" },
        params: {
            category_id: category_id,
        },
    });
};
