import React, { useEffect, useState } from "react";
import "./PaymentErrorPage.scss";
import { PAGE_URL } from "../../../constants/urls";
import { fetchLotByUUID } from "../../../services/lotService";
import { fetchOfferByUUID } from "../../../services/offerService";
import { ILot, ILotSimple } from "../../../types/lotTypes";
import CustomButton from "../../components/CustomButton/CustomButton";
import DesktopView from "../../components/DesktopView/DesktopView";
import MobileView from "../../components/MobileView/MobileView";
import PageBtnBack from "../../components/PageBtnBack/PageBtnBack";
import PageTopBackground from "../../components/PageTopBackground/PageTopBackground";
import TopMobileBackHeader from "../../components/TopMobileBackHeader";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import gifError from "../../../assets/gif/error.gif";
import { NavLink, useParams } from "react-router-dom";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

export interface IPaymentErrorPageProps {}

const PaymentErrorPage = ({}: IPaymentErrorPageProps) => {
    const { subject, uuid } = useParams<{ subject: "lot" | "offer"; uuid: string }>();
    const [lot, setLot] = useState<ILot | ILotSimple>();
    const [isRedirect, setIsRedirect] = useState<boolean>(!uuid);

    useEffect(() => {
        if (subject === "lot") {
            fetchLotByUUID(uuid)
                .then((response) => {
                    setLot(response.data);
                })
                .catch(() => {
                    setIsRedirect(true);
                });
        } else if (subject === "offer") {
            fetchOfferByUUID(uuid)
                .then((response) => {
                    setLot(response.data.lot);
                })
                .catch(() => {
                    setIsRedirect(true);
                });
        }
    }, []);

    if (isRedirect) {
        return <NotFoundPage />;
    }

    return (
        <MainLayout withoutOverflow>
            <MobileView>
                {subject === "lot" && <TopMobileBackHeader>Оплата лоту №{lot?.id}</TopMobileBackHeader>}
                {subject === "offer" && <TopMobileBackHeader>Оплата пропозиції до лоту №{lot?.id}</TopMobileBackHeader>}
            </MobileView>

            <div className="page payment-page-error">
                <PageTopBackground />
                <div className="content">
                    <DesktopView>
                        <PageBtnBack />

                        {subject === "lot" && <div className="payment-page-error__title">Оплата лоту №{lot?.id}</div>}
                        {subject === "offer" && (
                            <div className="payment-page-error__title">Оплата пропозиції до лоту №{lot?.id}</div>
                        )}
                    </DesktopView>

                    <div className="payment-page-error__block">
                        <div className="payment-page-error__block-icon">
                            <img src={gifError} width={130} height={140} alt="icon_error" />
                        </div>
                        <div className="payment-page-error__block-title">Ой, помилка оплати</div>
                        <div className="payment-page-error__block-description">
                            Ми не змогли обробити ваш платіж. Будь ласка, спробуйте, ще раз
                        </div>
                        <NavLink
                            to={PAGE_URL.PAYMENT.replace(":subject", subject).replace(":uuid", uuid)}
                            className="payment-page-error__block-btn"
                        >
                            <CustomButton>Спробувати ще раз</CustomButton>
                        </NavLink>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default PaymentErrorPage;
