import { MobileMenuActionType } from "../store/reducers/mobileMenuReducer";

export const closeMobileMenu = () => {
    return { type: MobileMenuActionType.CLOSE };
};

export const openMobileMenu = () => {
    return { type: MobileMenuActionType.OPEN };
};

export const switchMobileMenu = (isMobileMenuOpen: boolean) => {
    return isMobileMenuOpen ? closeMobileMenu() : openMobileMenu();
};
