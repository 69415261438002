import axios, { AxiosResponse } from "axios";
import qs from "qs";
import { API_DOMAIN, API_PATH } from "../constants/api";
import { ICreateLotParams, IFetchLotListProps, ILot, ILotsResponse } from "../types/lotTypes";
import { DepositType } from "../types/mainTypes";

export const fetchProfileLotList = (params: IFetchLotListProps): Promise<AxiosResponse<ILotsResponse>> => {
    let queryString = qs.stringify(params, { addQueryPrefix: true });

    return axios.get(API_DOMAIN + API_PATH.PROFILE_LOTS + queryString);
};

export const fetchLotList = (params: IFetchLotListProps): Promise<AxiosResponse<ILotsResponse>> => {
    let queryString = qs.stringify(params, { addQueryPrefix: true });

    return axios.get(API_DOMAIN + API_PATH.LOTS + queryString);
};

export const fetchLotByUUID = (uuid: string): Promise<AxiosResponse<ILot>> => {
    return axios.get(API_DOMAIN + API_PATH.LOTS + `/${uuid}`);
};

export const createLot = (params: ICreateLotParams): Promise<AxiosResponse<ILot>> => {
    return axios.post(API_DOMAIN + API_PATH.LOTS, params);
};

export const editLot = (lotUUID: string, params: ICreateLotParams) => {
    return axios.put(`${API_DOMAIN}${API_PATH.LOTS}/${lotUUID}`, params);
};

export const lotsAverageCost = (params: any) => {
    const url = `${API_DOMAIN}/lots_average_cost`;

    return axios.get(url, {
        headers: { "Content-Type": "text/plain" },
        params: params,
    });
};

export const getDepositSum = (
    type: DepositType,
): Promise<AxiosResponse<{ deposit_sum: number; commission_sum: number }>> => {
    return axios.get(API_DOMAIN + API_PATH.DEPOSIT_SUM + `?deposit_type=${type}`);
};

export const createDeposit = (lotUUID: string): Promise<AxiosResponse<ILot>> => {
    return axios.post(API_DOMAIN + API_PATH.DEPOSIT, { lot_uuid: lotUUID });
};

export const cancelLot = (lotUUID: string): Promise<AxiosResponse<any>> => {
    return axios.put(`${API_DOMAIN}${API_PATH.LOT_CANCEL}/${lotUUID}`);
};

export const sendViewEvent = (uuid: string): Promise<AxiosResponse<any>> => {
    return axios.patch(API_DOMAIN + API_PATH.LOTS + `/${uuid}`, { count: true });
};

export const publishLot = (lotUUID: string): Promise<AxiosResponse<ILot>> => {
    return axios.post(API_DOMAIN + API_PATH.LOT_PUBLISH.replace(":uuid", lotUUID));
};
