import axios from "axios";
import { API_DOMAIN, API_PATH } from "../constants/api";

export const fetchCategoryList = () => {
    return axios.get(API_DOMAIN + API_PATH.CATEGORY_LIST);
};

export const fetchCategory = (id: string) => {
    return axios.get(API_DOMAIN + API_PATH.CATEGORY_LIST + `/${id}`);
};

export interface IUpdateCategoryParams {
    name: string;
    order_id: number;
    main_category_id: number;
    active: boolean;
}

export const updateCategory = (id: string, params: IUpdateCategoryParams) => {
    return axios.put(API_DOMAIN + API_PATH.CATEGORY_LIST + `/${id}`, params);
};
