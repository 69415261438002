import React, { useEffect, useRef } from "react";
import "./MainLayout.scss";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header/Header";
import { IDeviceState } from "../../../types/deviceTypes";
import { useTypeSelector } from "../../hooks/useTypeSelector";
import clsx from "clsx";

function MainLayout(props: { children: any; withoutOverflow?: boolean }) {
    let history = useHistory();
    let { isMobile, isDesktop }: IDeviceState = useTypeSelector((state) => state.device);
    let { isOpen } = useTypeSelector((state) => state.mobileMenu);
    let scrollTopY = useRef<number>();
    let scrollElement = useRef<any>();

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (window && isMobile) {
            if (scrollElement.current) {
                if (isOpen) {
                    scrollTopY.current = window.scrollY;

                    timeoutId = setTimeout(() => {
                        scrollElement.current.style.position = "fixed";
                    }, 200);
                } else {
                    scrollElement.current.style.position = "";
                    window.scrollTo(0, scrollTopY.current || 0);
                }
            }

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [isOpen]);

    useEffect(() => {
        let location = { ...history.location, state: undefined };
        history.replace(location); // clear history state on reload page
    }, []);

    return (
        <div
            className={clsx("layout", {
                "is-mobile": isMobile,
                "is-desktop": isDesktop,
            })}
        >
            <div className="layout__header">
                <Header />
            </div>
            <div className={clsx("layout__content", { withoutOverflow: props.withoutOverflow })} ref={scrollElement}>
                {props.children}
            </div>
        </div>
    );
}

export default MainLayout;
