import axios, { AxiosResponse } from "axios";
import { API_DOMAIN, API_PATH } from "../constants/api";
import { IECPSign } from "../types/mainTypes";
import {
    ILoginInputs,
    IRegisterCompanyDataInputs,
    IRegisterInputs,
    IRegisterPersonalDataInputs,
} from "../types/userTypes";

export const fetchLoginUser = (data: ILoginInputs): Promise<AxiosResponse<any>> => {
    let { username, password } = data;

    return axios.post(API_DOMAIN + API_PATH.LOGIN, { username, password }, {});
};

export const fetchRegisterUser = (data: IRegisterInputs) => {
    let { email, password } = data;

    return axios.post(
        API_DOMAIN + API_PATH.REGISTER_STEP_1,
        { email, password },
        { headers: { "Content-Type": "text/plain" } },
    );
};

export const fetchRegisterPersonalData = (data: IRegisterPersonalDataInputs) => {
    let { email, phone_number } = data;

    return axios.post(
        API_DOMAIN + API_PATH.REGISTER_STEP_2,
        { email, phone_number },
        { headers: { "Content-Type": "text/plain" } },
    );
};

export const fetchRegisterCompanyData = (data: IRegisterCompanyDataInputs) => {
    let { name, inn, city_id } = data;

    return axios.post(
        API_DOMAIN + API_PATH.REGISTER_STEP_3,
        { name, inn, city_id },
        { headers: { "Content-Type": "text/plain" } },
    );
};

export const changePassword = (password: string, new_password: string): Promise<AxiosResponse<any>> => {
    return axios.patch(API_DOMAIN + API_PATH.CHANGE_PASSWORD, { password, new_password });
};

export const loginStep1 = () => {
    return axios.post(API_DOMAIN + API_PATH.LOGIN_STEP_1, { "pre-auth": true });
};

export const loginStep2 = ({
    pre_token,
    data,
    sign,
}: {
    pre_token: string;
    data: string;
    sign: IECPSign;
}): Promise<AxiosResponse<any>> => {
    return axios.post(API_DOMAIN + API_PATH.LOGIN_STEP_1, {
        "pre-auth-token": pre_token,
        data,
        sign,
    });
};
