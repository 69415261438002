import { combineReducers } from "redux";
import { categoriesReducer } from "./categoriesReducer";
import { deviceReducer } from "./deviceReducer";
import { exchangeRatesReducer } from "./exchangeRatesReducer";
import { fieldsReducer } from "./fieldsReducer";
import { lotsReducer } from "./lotsReducer";
import { lotListViewReducer } from "./lotListViewReducer";
import { mobileMenuReducer } from "./mobileMenuReducer";
import { regionsReducer } from "./regionsReducer";
import { userReducer } from "./userReducer";

export const rootReducer = combineReducers({
    user: userReducer,
    device: deviceReducer,
    mobileMenu: mobileMenuReducer,
    lotListView: lotListViewReducer,
    categoryList: categoriesReducer,
    regionList: regionsReducer,
    lotList: lotsReducer,
    fieldList: fieldsReducer,
    exchangeRates: exchangeRatesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
