import moment from "moment/moment";
import React, { lazy, Suspense } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "../../interceptors/tokenIntercepter";
import { PAGE_URL } from "../../constants/urls";
import { fetchCategoryListAction } from "../actions/categoryActions";
import { fetchExchangeRatesAction } from "../actions/exchangeRatesActions";
import { fetchFieldListAction } from "../actions/fieldActions";
import { fetchRegionListAction } from "../actions/regionActions";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";
// import ContactUsPage from "../pages/ContactUsPage/ContactUsPage";
// import CookiesPage from "../pages/CookiesPage/CookiesPage";
// import CreateLotPage from "../pages/CreateLotPage/CreateLotPage";
// import CreateLotSubs from "../pages/CreateLotSubs/CreateLotSubs";
// import CreateOfferPage from "../pages/CreateOfferPage/CreateOfferPage";
// import DealPage from "../pages/DealPage/DealPage";
// import EditLotPage from "../pages/EditLotPage/EditLotPage";
// import IndexPage from "../pages/IndexPage/IndexPage";
// import LoginECPPage from "../pages/LoginECPPage/LoginECPPage";
// import LoginPage from "../pages/LoginPage/LoginPage";
// import LotOffersPage from "../pages/LotOffersPage/LotOffersPage";
// import LotPage from "../pages/LotPage/LotPage";
// import LotsPage from "../pages/LotsPage/LotsPage";
// import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
// import PolicyPage from "../pages/PolicyPage/PolicyPage";
// import DashboardPage from "../pages/ProfilePages/DashboardPage/DashboardPage";
// import NotificationsPage from "../pages/ProfilePages/NotificationsPage/NotificationsPage";
// import ProfileOffersPage from "../pages/ProfilePages/ProfileOffersPage/ProfileOffersPage";
// import ProfileChangePassword from "../pages/ProfilePages/ProfileChangePassword/ProfileChangePassword";
// import ProfileDealItemPage from "../pages/ProfilePages/ProfileDealItemPage/ProfileDealItemPage";
// import ProfileDealsPage from "../pages/ProfilePages/ProfileDealsPage/ProfileDealsPage";
// import ProfileDepositsPage from "../pages/ProfilePages/ProfileDepositsPage/ProfileDepositsPage";
// import ProfileLotsPage from "../pages/ProfilePages/ProfileLotsPage/ProfileLotsPage";
// import ProfileSettingsPage from "../pages/ProfilePages/ProfileSettingsPage/ProfileSettingsPage";
// import RegisterPage from "../pages/RegisterPage/RegisterPage";
// import TermsPage from "../pages/TermsPage/TermsPage";
// import PaymentPage from "../pages/PaymentPage/PaymentPage";
// import AboutPage from "../pages/AboutPage/AboutPage";
import "../../static/robots.txt";
import "../../static/sitemap.xml";
import "./App.scss";
import PaymentErrorPage from "../pages/PaymentErrorPage/PaymentErrorPage";
import PaymentSuccessPage from "../pages/PaymentSuccessPage/PaymentSuccessPage";

const ContactUsPage = lazy(() => import("../pages/ContactUsPage/ContactUsPage"));
const CookiesPage = lazy(() => import("../pages/CookiesPage/CookiesPage"));
const CreateLotPage = lazy(() => import("../pages/CreateLotPage/CreateLotPage"));
const CreateLotSubs = lazy(() => import("../pages/CreateLotSubs/CreateLotSubs"));
const CreateOfferPage = lazy(() => import("../pages/CreateOfferPage/CreateOfferPage"));
const DealPage = lazy(() => import("../pages/DealPage/DealPage"));
const EditLotPage = lazy(() => import("../pages/EditLotPage/EditLotPage"));
const IndexPage = lazy(() => import("../pages/IndexPage/IndexPage"));
const LoginECPPage = lazy(() => import("../pages/LoginECPPage/LoginECPPage"));
const LoginPage = lazy(() => import("../pages/LoginPage/LoginPage"));
const LotOffersPage = lazy(() => import("../pages/LotOffersPage/LotOffersPage"));
const LotPage = lazy(() => import("../pages/LotPage/LotPage"));
const LotsPage = lazy(() => import("../pages/LotsPage/LotsPage"));
const NotFoundPage = lazy(() => import("../pages/NotFoundPage/NotFoundPage"));
const PolicyPage = lazy(() => import("../pages/PolicyPage/PolicyPage"));
const DashboardPage = lazy(() => import("../pages/ProfilePages/DashboardPage/DashboardPage"));
const NotificationsPage = lazy(() => import("../pages/ProfilePages/NotificationsPage/NotificationsPage"));
const ProfileOffersPage = lazy(() => import("../pages/ProfilePages/ProfileOffersPage/ProfileOffersPage"));
const ProfileChangePassword = lazy(() => import("../pages/ProfilePages/ProfileChangePassword/ProfileChangePassword"));
const ProfileDealItemPage = lazy(() => import("../pages/ProfilePages/ProfileDealItemPage/ProfileDealItemPage"));
const ProfileDealsPage = lazy(() => import("../pages/ProfilePages/ProfileDealsPage/ProfileDealsPage"));
const ProfileDepositsPage = lazy(() => import("../pages/ProfilePages/ProfileDepositsPage/ProfileDepositsPage"));
const ProfileLotsPage = lazy(() => import("../pages/ProfilePages/ProfileLotsPage/ProfileLotsPage"));
const ProfileSettingsPage = lazy(() => import("../pages/ProfilePages/ProfileSettingsPage/ProfileSettingsPage"));
const RegisterPage = lazy(() => import("../pages/RegisterPage/RegisterPage"));
const TermsPage = lazy(() => import("../pages/TermsPage/TermsPage"));
const PaymentPage = lazy(() => import("../pages/PaymentPage/PaymentPage"));
const AboutPage = lazy(() => import("../pages/AboutPage/AboutPage"));

function App() {
    console.log("Build time:", new Date(VERSION).toLocaleString("ru-RU"));

    const dispatch = useDispatch();

    dispatch(fetchCategoryListAction());
    dispatch(fetchRegionListAction());
    dispatch(fetchFieldListAction());
    dispatch(fetchExchangeRatesAction());

    moment.locale("uk");

    setInterval(fetchExchangeRatesAction, 1000 * 60);

    return (
        <Router>
            <Suspense fallback={<div />}>
                <Switch>
                    <Route exact path={PAGE_URL.LOGIN_OLD} component={LoginPage} />
                    <Route exact path={PAGE_URL.LOGIN} component={LoginECPPage} />
                    <Route exact path={PAGE_URL.REGISTER} component={RegisterPage} />
                    <Route exact path={PAGE_URL.DEALS} component={DealPage} />
                    <Route exact path={PAGE_URL.INDEX} component={IndexPage} />
                    <Route exact path={PAGE_URL.POLICY} component={PolicyPage} />
                    <Route exact path={PAGE_URL.COOKIES} component={CookiesPage} />
                    <Route exact path={PAGE_URL.TERMS} component={TermsPage} />
                    <Route exact path={PAGE_URL.CONTACT_US} component={ContactUsPage} />
                    <Route exact path={PAGE_URL.ABOUT_US} component={AboutPage} />
                    <PrivateRoute exact path={PAGE_URL.LOTS} component={LotsPage} />
                    <PrivateRoute exact path={PAGE_URL.LOT_ITEM} component={LotPage} />
                    <PrivateRoute exact path={PAGE_URL.LOT_EDIT_OFFER} component={CreateOfferPage} />
                    <PrivateRoute exact path={PAGE_URL.LOT_CREATE_OFFER} component={CreateOfferPage} />
                    <PrivateRoute exact path={PAGE_URL.LOT_OFFER_LIST} component={LotOffersPage} />
                    <PrivateRoute exact path={PAGE_URL.LOT_ITEM_EDIT} component={EditLotPage} />
                    <PrivateRoute exact path={PAGE_URL.LOT_CREATE_SUBS} component={CreateLotSubs} />
                    <PrivateRoute exact path={PAGE_URL.CREATE_LOT} component={CreateLotPage} />
                    <PrivateRoute exact path={PAGE_URL.PAYMENT} component={PaymentPage} />
                    <PrivateRoute exact path={PAGE_URL.PAYMENT_SUCCESS} component={PaymentSuccessPage} />
                    <PrivateRoute exact path={PAGE_URL.PAYMENT_ERROR} component={PaymentErrorPage} />
                    <PrivateRoute exact path={PAGE_URL.MY_DASHBOARD} component={DashboardPage} />
                    <PrivateRoute exact path={PAGE_URL.MY_NOTIFICATIONS} component={NotificationsPage} />
                    <PrivateRoute exact path={PAGE_URL.MY_OFFERS} component={ProfileOffersPage} />
                    <PrivateRoute exact path={PAGE_URL.MY_LOTS} component={ProfileLotsPage} />
                    <PrivateRoute exact path={PAGE_URL.MY_DEALS} component={ProfileDealsPage} />
                    <PrivateRoute exact path={PAGE_URL.MY_DEAL_ITEM} component={ProfileDealItemPage} />
                    <PrivateRoute exact path={PAGE_URL.MY_DEPOSITS} component={ProfileDepositsPage} />
                    <PrivateRoute exact path={PAGE_URL.MY_SETTINGS} component={ProfileSettingsPage} />
                    <PrivateRoute exact path={PAGE_URL.CHANGE_PASSWORD} component={ProfileChangePassword} />
                    <Route path="*" component={NotFoundPage} />
                </Switch>
            </Suspense>
        </Router>
    );
}

export default App;
