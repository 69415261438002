import { IUser, UserActionTypes, UserLoginAction, IUserState, UserRegisterAction } from "../../../types/userTypes";
import { getUserData } from "../../../utils/localStorage";

const initialState: IUserState = {
    user: getUserData(),
    loading: false,
    error: null,
};

export const userReducer = (
    state: IUserState = initialState,
    action: UserLoginAction | UserRegisterAction,
): IUserState => {
    switch (action.type) {
        case UserActionTypes.LOGIN:
            return { ...state, loading: true, error: null };
        case UserActionTypes.LOGIN_SUCCESS:
            let user: IUser = action.payload;

            return { loading: false, error: null, user };
        case UserActionTypes.LOGIN_ERROR:
            return { loading: false, error: action.payload, user: null };
        case UserActionTypes.LOGOUT:
            return { loading: false, error: null, user: null };
        case UserActionTypes.REGISTER:
            return { ...state, loading: true, error: null };
        case UserActionTypes.REGISTER_SUCCESS:
            if (true) {
                let user: IUser = action.payload;

                return { loading: false, error: null, user };
            }
        case UserActionTypes.REGISTER_ERROR:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};
