import clsx from "clsx";
import React from "react";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import "./MobileMenu.scss";
import { PAGE_URL } from "../../../constants/urls";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { logoutUser } from "../../actions/authActions";
import { closeMobileMenu } from "../../actions/mobileMenuActions";
import { useTypeSelector } from "../../hooks/useTypeSelector";
import { IHistoryState } from "../Header/Header";

const MobileMenu = () => {
    let { user } = useTypeSelector((state) => state.user);
    let dispatch = useDispatch();
    let history = useHistory<IHistoryState>();
    let { isOpen } = useTypeSelector((state) => state.mobileMenu);
    let classNames = clsx("m-menu", {
        "m-menu_open": isOpen,
    });

    // useEffect(() => {
    //     setTimeout(() => {
    //         dispatch(closeMobileMenu());
    //     }, 20);
    // }, [history.location.pathname, history.location.search]);

    let onLogoutClick = () => {
        dispatch(logoutUser());
        history.push(PAGE_URL.LOGIN);
    };

    let onMenuItemClick = () => {
        setTimeout(() => {
            dispatch(closeMobileMenu());
        }, 20);
    };

    let getMobMenuLink = (title: string, link: string, onClick?: any) => {
        return (
            <NavLink
                to={link}
                className="m-menu__cabinet-links-item"
                onClick={() => {
                    onMenuItemClick();

                    if (onClick) onClick();
                }}
            >
                <span>{title}</span>
                <ArrowForwardIosIcon className={"m-menu__link-arrow"} />
            </NavLink>
        );
    };

    let authUserBlock = (
        <>
            {getMobMenuLink("Центр управління", PAGE_URL.MY_DASHBOARD)}
            {getMobMenuLink("Сповіщення", PAGE_URL.MY_NOTIFICATIONS)}
            {getMobMenuLink("Мої лоти", PAGE_URL.MY_LOTS)}
            {getMobMenuLink("Мої пропозиції", PAGE_URL.MY_OFFERS)}
            {getMobMenuLink("Мої контракти", PAGE_URL.MY_DEALS)}
            {user?.permissions.includes("view deposit menu")
                ? getMobMenuLink("Мої депозити", PAGE_URL.MY_DEPOSITS)
                : null}
            {/*{getMobMenuLink("Налаштування", PAGE_URL.MY_SETTINGS)}*/}
            {getMobMenuLink("Вихід", "/", onLogoutClick)}
        </>
    );

    let nonAuthUserBlock = (
        <>
            {getMobMenuLink("Увійти", PAGE_URL.LOGIN)}
            {getMobMenuLink("Зареєструватися", PAGE_URL.REGISTER)}
        </>
    );

    return (
        <div className={classNames}>
            <div className="m-menu__pages">
                <NavLink
                    to={PAGE_URL.LOTS}
                    className={"m-menu__link"}
                    activeClassName={"active"}
                    onClick={onMenuItemClick}
                >
                    <span>Торги</span>
                    <ArrowForwardIosIcon className={"m-menu__link-arrow"} />
                </NavLink>
                <NavLink
                    to={PAGE_URL.DEALS}
                    className={"m-menu__link"}
                    activeClassName={"active"}
                    onClick={onMenuItemClick}
                >
                    <span>Контракти</span>
                    <ArrowForwardIosIcon className={"m-menu__link-arrow"} />
                </NavLink>
                <NavLink
                    to={PAGE_URL.CREATE_LOT}
                    className={"m-menu__link"}
                    activeClassName={"active"}
                    onClick={onMenuItemClick}
                >
                    <span>Створити лот</span>
                    <ArrowForwardIosIcon className={"m-menu__link-arrow"} />
                </NavLink>
            </div>
            <div className="m-menu__cabinet">
                <div className="m-menu__cabinet-title">Особистий кабінет</div>
                <div className="m-menu__cabinet-links">{user ? authUserBlock : nonAuthUserBlock}</div>
            </div>
            {/*<div className="m-menu__policy">*/}
            {/*    <NavLink to={"/"} className="m-menu__policy-item">*/}
            {/*        Політика конфеденційності*/}
            {/*    </NavLink>*/}
            {/*    <NavLink to={"/"} className="m-menu__policy-item">*/}
            {/*        Умови користування*/}
            {/*    </NavLink>*/}
            {/*    <NavLink to={"/"} className="m-menu__policy-item">*/}
            {/*        Політика у відношенні Cookies*/}
            {/*    </NavLink>*/}
            {/*</div>*/}
        </div>
    );
};

export default MobileMenu;
