import React from "react";
import "./PageTopBackground.scss";
import page_bg from "../../../assets/images/page_top_background.svg";
import DesktopView from "../DesktopView/DesktopView";

const PageTopBackground = () => {
    return (
        <DesktopView>
            <div className="page-top-background">
                <img src={page_bg} alt="background" />
            </div>
        </DesktopView>
    );
};

export default PageTopBackground;
