import { Dispatch } from "redux";
import { fetchRegions } from "../../services/regionService";
import { IRegion } from "../../types/regionTypes";
import { RegionActionTypes } from "../store/reducers/regionsReducer";

export const fetchRegionListAction = () => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({ type: RegionActionTypes.FETCH });

            const response = await fetchRegions();
            const list: IRegion[] = response.regions;

            dispatch({ type: RegionActionTypes.FETCH_SUCCESS, payload: list });
        } catch (e) {
            dispatch({ type: RegionActionTypes.FETCH_ERROR, payload: "Ошибка при загрузке списка регионов!" });
        }
    };
};
