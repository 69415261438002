import React, { useCallback } from "react";
import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

type TopMobileBackHeaderProps = {
    onPressBack?: () => void;
};

const TopMobileBackHeader: React.FC<TopMobileBackHeaderProps> = ({ onPressBack, children }) => {
    const history = useHistory();

    const handleGoBack = useCallback(() => {
        if (onPressBack) {
            onPressBack();
        } else {
            history.goBack();
        }
    }, []);

    return (
        <div className={styles.topMobileBackHeader}>
            <div onClick={handleGoBack} className={styles.backButton}>
                <ArrowBackIosIcon style={{ fontSize: 16 }} />
            </div>
            <div className={styles.headerTitle}>{children}</div>
        </div>
    );
};

export default TopMobileBackHeader;
