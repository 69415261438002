import { Dispatch } from "redux";
import { getExchangeRate } from "../../services/usersService";
import { ExchangeRangeActionTypes, IExchangeRange } from "../store/reducers/exchangeRatesReducer";

export const fetchExchangeRatesAction = () => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({ type: ExchangeRangeActionTypes.FETCH });

            const response = await getExchangeRate();
            const data: IExchangeRange = response.data;

            dispatch({ type: ExchangeRangeActionTypes.FETCH_SUCCESS, payload: data });
        } catch (e) {
            dispatch({ type: ExchangeRangeActionTypes.FETCH_ERROR, payload: "Ошибка при загрузке списка категорий" });
        }
    };
};
