import axios, { AxiosResponse } from "axios";
import { API_DOMAIN, API_PATH } from "../constants/api";
import { PaginationData, IPaginationParams } from "../types/paginationTypes";
import { IBalance, IUser } from "../types/userTypes";

export interface UsersResponseData {
    users: IUser[];
    order_by: string;
    pagination: PaginationData;
}

export interface IUpdateUserParams {
    username: string;
    email: string;
    phone_number: string;
    password?: string;
    first_name: string;
    last_name: string;
    second_name: string;
    role_id: number;
    customer_uuid: string;
}

export async function fetchUsers({ page, limit }: IPaginationParams): Promise<UsersResponseData> {
    const url = `${API_DOMAIN}/users`;
    const params = {
        from: page * limit - limit + 1,
        to: page * limit,
    };

    const { data } = await axios.get(url, {
        headers: { "Content-Type": "text/plain" },
        params: params,
    });

    return data;
}

export async function fetchUser(uuid: string): Promise<IUser> {
    const url = `${API_DOMAIN}/users/${uuid}`;

    const { data } = await axios.get(url, {
        headers: { "Content-Type": "text/plain" },
    });

    return data;
}

export async function getRoles() {
    const url = `${API_DOMAIN}/roles`;

    const { data } = await axios.get(url, {
        headers: { "Content-Type": "text/plain" },
    });

    return data;
}

export async function logout() {
    const url = `${API_DOMAIN}/logout`;

    return await axios.post(url);
}

export function updateUser(uuid: string, params: IUpdateUserParams) {
    const url = `${API_DOMAIN}/users/${uuid}`;

    return axios.put(url, params);
}

export function getExchangeRate(): Promise<AxiosResponse<{ [key: number]: number }>> {
    const url = `${API_DOMAIN + API_PATH.EXCHANGE_RATES}`;

    return axios.get(url);
}

export function getUserBalance(): Promise<AxiosResponse<IBalance>> {
    const url = `${API_DOMAIN + API_PATH.USER_BALANCE}`;

    return axios.get(url);
}

// export function replenishUserBalance(): Promise<AxiosResponse<any>> {
//
// }
