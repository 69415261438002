export enum MobileMenuActionType {
    OPEN = "OPEN_MOBILE_MENU",
    CLOSE = "CLOSE_MOBILE_MENU",
}

export interface IMobileMenuOpen {
    type: MobileMenuActionType.OPEN;
}

export interface IMobileMenuClose {
    type: MobileMenuActionType.CLOSE;
}

export type MobileMenuAction = IMobileMenuOpen | IMobileMenuClose;

export interface IMobileMenuState {
    isOpen: boolean;
}

function checkIsMobileMenuOpen(): boolean {
    // let queryString =
    return false;
}

const initialState: IMobileMenuState = {
    isOpen: checkIsMobileMenuOpen(),
};

export const mobileMenuReducer = (state: IMobileMenuState = initialState, action: MobileMenuAction) => {
    switch (action.type) {
        case MobileMenuActionType.OPEN:
            return { isOpen: true };
        case MobileMenuActionType.CLOSE:
            return { isOpen: false };
        default:
            return state;
    }
};
