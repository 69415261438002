export interface IDeviceState {
    isDesktop: boolean;
    isMobile: boolean;
}

export enum DeviceActionTypes {
    SWITCH_ON_DESKTOP = "SWITCH_ON_DESKTOP",
    SWITCH_ON_MOBILE = "SWITCH_ON_MOBILE",
}

export interface IDeviceSwitchDesktop {
    type: DeviceActionTypes.SWITCH_ON_DESKTOP;
}

export interface IDeviceSwitchMobile {
    type: DeviceActionTypes.SWITCH_ON_MOBILE;
}

export type DeviceSwitchAction = IDeviceSwitchDesktop | IDeviceSwitchMobile;
