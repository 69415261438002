import { ICategory } from "../../../types/categoryTypes";

export enum CategoryActionTypes {
    FETCH = "FETCH_CATEGORY",
    FETCH_SUCCESS = "FETCH_CATEGORY_SUCCESS",
    FETCH_ERROR = "FETCH_CATEGORY_ERROR",
}

export interface ICategoryFetchAction {
    type: CategoryActionTypes.FETCH;
}

export interface ICategoryFetchSuccessAction {
    type: CategoryActionTypes.FETCH_SUCCESS;
    payload: ICategory[];
}

export interface ICategoryFetchErrorAction {
    type: CategoryActionTypes.FETCH_ERROR;
    payload: string;
}

export type CategoryActionType = ICategoryFetchAction | ICategoryFetchSuccessAction | ICategoryFetchErrorAction;

export interface ICategoryListState {
    list: null | ICategory[];
    loading: boolean;
    error: null | string;
}

const initialState: ICategoryListState = {
    list: null,
    loading: false,
    error: null,
};

export const categoriesReducer = (
    state: ICategoryListState = initialState,
    action: CategoryActionType,
): ICategoryListState => {
    switch (action.type) {
        case CategoryActionTypes.FETCH:
            return { ...state, loading: true, error: null };
        case CategoryActionTypes.FETCH_SUCCESS:
            let list: ICategory[] = action.payload;

            return { loading: false, error: null, list };
        case CategoryActionTypes.FETCH_ERROR:
            return { loading: false, error: action.payload, list: null };
        default:
            return state;
    }
};
