import { Dispatch } from "redux";
import { ErrorCode } from "../../constants/errors";
import {
    fetchLoginUser,
    fetchRegisterCompanyData,
    fetchRegisterPersonalData,
    fetchRegisterUser,
} from "../../services/authService";
import { logout } from "../../services/usersService";
import {
    ILoginUserResponse,
    IUser,
    ILoginInputs,
    UserActionTypes,
    UserLoginAction,
    IRegisterInputs,
    UserRegisterAction,
    IRegisterUserResponse,
    IRegisterPersonalDataInputs,
    IRegisterPersonalDataResponse,
    IRegisterCompanyDataInputs,
} from "../../types/userTypes";
import { getUserData, removeAuthData, setAuthData } from "../../utils/localStorage";

export const loginUser = (formData: ILoginInputs) => {
    return async (dispatch: Dispatch<UserLoginAction>) => {
        try {
            dispatch({ type: UserActionTypes.LOGIN });

            const response = await fetchLoginUser(formData);
            const loginResponse: ILoginUserResponse = response.data;

            setAuthData(loginResponse);

            const user: IUser | null = getUserData();

            if (user) {
                dispatch({ type: UserActionTypes.LOGIN_SUCCESS, payload: user });
            }
        } catch ({ response }) {
            let error: string;

            if ((response as any)?.status === 401) {
                error = "Некоректний логін або пароль!";
            } else {
                error = "Помилка інтернета або сервера";
            }

            dispatch({ type: UserActionTypes.LOGIN_ERROR, payload: error });
        }
    };
};

export const registerUser = (formData: IRegisterInputs) => {
    return async (dispatch: Dispatch<UserRegisterAction>) => {
        try {
            dispatch({ type: UserActionTypes.REGISTER });

            const response = await fetchRegisterUser(formData);
            const loginResponse: IRegisterUserResponse = response.data.auth_data;

            setAuthData(loginResponse);

            const user: IUser | null = getUserData();

            if (user) {
                dispatch({ type: UserActionTypes.REGISTER_SUCCESS, payload: user });
            }
        } catch ({ response }) {
            let error: string;

            if ((response as any)?.status === 400) {
                error = "Такий email вже зареєстрований!";
            } else {
                error = "Ошибка сервера или интернета!";
            }

            dispatch({ type: UserActionTypes.REGISTER_ERROR, payload: error });
        }
    };
};

export const registerPersonalData = (formData: IRegisterPersonalDataInputs) => {
    return async (dispatch: Dispatch<UserRegisterAction>) => {
        try {
            dispatch({ type: UserActionTypes.REGISTER });

            const response = await fetchRegisterPersonalData(formData);
            const loginResponse: IRegisterPersonalDataResponse = response.data.auth_data;

            setAuthData(loginResponse);

            const user: IUser | null = getUserData();

            if (user) {
                dispatch({ type: UserActionTypes.REGISTER_SUCCESS, payload: user });
            }
        } catch (e: any) {
            let error: string;
            let responseError: { code: ErrorCode; text: string } = e.response?.data?.error;

            if (responseError) {
                switch (responseError.code) {
                    case ErrorCode.EMAIL_EXISTS:
                        error = "Такий email вже зареєстрованний в системі!";
                        break;
                    case ErrorCode.PHONE_EXISTS:
                        error = "Такий номер телефону вже зареєстрованний в системі!";
                        break;
                    default:
                        error = "Помилково заповнені поля!";
                        break;
                }
            } else {
                if (e?.response?.status === 400) {
                    error = "Помилково заповнені поля!";
                } else {
                    error = "Ошибка сервера или интернета!";
                }
            }

            dispatch({ type: UserActionTypes.REGISTER_ERROR, payload: error });
        }
    };
};

export const registerCompanyData = (formData: IRegisterCompanyDataInputs) => {
    return async (dispatch: Dispatch<UserRegisterAction>) => {
        try {
            dispatch({ type: UserActionTypes.REGISTER });

            const response = await fetchRegisterCompanyData(formData);
            const loginResponse: IRegisterPersonalDataResponse = response.data.auth_data;

            setAuthData(loginResponse);

            const user: IUser | null = getUserData();

            if (user) {
                dispatch({ type: UserActionTypes.REGISTER_SUCCESS, payload: user });
            }
        } catch ({ response }) {
            let error: string;

            if ((response as any)?.status === 400) {
                error = "Помилково заповнені поля!";
            } else {
                error = "Ошибка сервера или интернета!";
            }

            dispatch({ type: UserActionTypes.REGISTER_ERROR, payload: error });
        }
    };
};

export const logoutUser = (): ((dispatch: Dispatch<UserLoginAction>) => Promise<void>) => {
    return async (dispatch: Dispatch<UserLoginAction>) => {
        try {
            logout().then();

            setTimeout(() => {
                removeAuthData();
                dispatch({ type: UserActionTypes.LOGOUT });
            }, 0);
        } catch ({ response }) {
            // TODO handle error!
        }
    };
};
