import { Dispatch } from "redux";
import { fetchFieldList } from "../../services/fieldService";
import { FieldActionTypes, IField } from "../store/reducers/fieldsReducer";

export const fetchFieldListAction = () => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({ type: FieldActionTypes.FETCH });

            const response = await fetchFieldList();
            const list: IField[] = response.data.fields;
            const sortedList: IField[] = list.sort((a, b) => (a.id > b.id ? 1 : -1));

            dispatch({ type: FieldActionTypes.FETCH_SUCCESS, payload: sortedList });
        } catch (e) {
            dispatch({ type: FieldActionTypes.FETCH_ERROR, payload: "Ошибка при загрузке списка филдов" });
            // dispatch({ type: FieldActionTypes.FETCH_ERROR, payload: e.message });
        }
    };
};
