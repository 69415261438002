import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { NavLink, useParams } from "react-router-dom";
import gifSuccess from "../../../assets/gif/success.gif";
import { PAGE_URL } from "../../../constants/urls";
import { fetchLotByUUID } from "../../../services/lotService";
import { fetchOfferByUUID } from "../../../services/offerService";
import { ILot, ILotSimple } from "../../../types/lotTypes";
import CustomButton from "../../components/CustomButton/CustomButton";
import DesktopView from "../../components/DesktopView/DesktopView";
import MobileView from "../../components/MobileView/MobileView";
import PageBtnBack from "../../components/PageBtnBack/PageBtnBack";
import PageTopBackground from "../../components/PageTopBackground/PageTopBackground";
import TopMobileBackHeader from "../../components/TopMobileBackHeader";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import { SUBJECT } from "../PaymentPage/PaymentPage";
import "./PaymentSuccessPage.scss";

export interface IPaymentSuccessPageProps {}

const PaymentSuccessPage = ({}: IPaymentSuccessPageProps) => {
    const location = useLocation();
    const [noNeedToPay, setNoNeedToPay] = useState<boolean>(location.search.includes("f=1"));
    const { subject, uuid } = useParams<{ subject: SUBJECT; uuid: string }>();
    const [lot, setLot] = useState<ILot | ILotSimple>();
    const [isRedirect, setIsRedirect] = useState<boolean>(!uuid);
    const isComponentMounted = useRef<boolean>(true);

    const getTitle = () => {
        switch (subject) {
            case SUBJECT.LOT:
                return `Оплата лоту №${lot?.id}`;
            case SUBJECT.OFFER:
                return `Оплата пропозиції до лоту №${lot?.id}`;
        }
    };

    useEffect(() => {
        if (subject === SUBJECT.LOT) {
            fetchLotByUUID(uuid)
                .then((response) => {
                    setLot(response.data);
                })
                .catch(() => {
                    setIsRedirect(true);
                });
        } else if (subject === SUBJECT.OFFER) {
            fetchOfferByUUID(uuid)
                .then((response) => {
                    setLot(response.data.lot);
                })
                .catch(() => {
                    setIsRedirect(true);
                });
        }

        return () => {
            isComponentMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isComponentMounted.current) {
            setNoNeedToPay(location.search.includes("f=1"));
        }
    }, [location, location.search]);

    if (isRedirect) {
        return <NotFoundPage />;
    }

    return (
        <MainLayout withoutOverflow>
            <MobileView>
                <TopMobileBackHeader>{getTitle()}</TopMobileBackHeader>
            </MobileView>

            <div className="page payment-page-success">
                <PageTopBackground />
                <div className="content">
                    <DesktopView>
                        <PageBtnBack />

                        <div className="payment-page-success__title">{getTitle()}</div>
                    </DesktopView>

                    <div className="payment-page-success__block">
                        <div className="payment-page-success__block-icon">
                            <img src={gifSuccess} width={130} height={140} alt="icon_success" />
                        </div>
                        <div className="payment-page-success__block-title">
                            {subject === SUBJECT.LOT && "Лот опубліковано!"}
                            {subject === SUBJECT.OFFER && "Пропозиція до лоту опублікована!"}
                            {subject === SUBJECT.BALANCE && "Баланс поповнено!"}
                        </div>
                        <div className="payment-page-success__block-description">
                            {noNeedToPay
                                ? "Дякую, що обрали Agro.Forum"
                                : "Ваша оплата пройшла успішно. Дякую, що обрали Agro.Forum"}
                        </div>
                        {subject === SUBJECT.BALANCE ? (
                            <NavLink to={PAGE_URL.MY_DASHBOARD} className="payment-page-success__block-btn">
                                <CustomButton>Перейти на сторінку профіля</CustomButton>
                            </NavLink>
                        ) : (
                            <NavLink
                                to={PAGE_URL.LOT_ITEM.replace(":uuid", lot?.uuid || "")}
                                className="payment-page-success__block-btn"
                            >
                                <CustomButton>Перейти на сторінку лоту</CustomButton>
                            </NavLink>
                        )}
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default PaymentSuccessPage;
