export enum LotListViewTypes {
    CARD = "card",
    TABLE = "table",
}

export enum LotListViewAction {
    SWITCH_VIEW = "SWITCH_VIEW",
    // TO_TABLE = "TO_TABLE",
    // TO_CARD = "TO_CARD",
}

export interface ILotListViewToTable {
    type: LotListViewAction.SWITCH_VIEW;
    payload: LotListViewTypes.TABLE;
}

export interface ILotListViewToCard {
    type: LotListViewAction.SWITCH_VIEW;
    payload: LotListViewTypes.CARD;
}

export type LotListViewActionType = ILotListViewToTable | ILotListViewToCard;

export interface ILotListViewState {
    view: LotListViewTypes;
}

const localStorageKey = "LOT_LIST_VIEW";

let initialState: ILotListViewState = {
    view: (localStorage.getItem(localStorageKey) as LotListViewTypes) || LotListViewTypes.CARD,
};

export const lotListViewReducer = (state: ILotListViewState = initialState, action: LotListViewActionType) => {
    switch (action.type) {
        case LotListViewAction.SWITCH_VIEW:
            localStorage.setItem(localStorageKey, action.payload);
            return { view: action.payload };
        default:
            return state;
    }
};
