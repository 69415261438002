import axios from "axios";
import { API_DOMAIN } from "../constants/api";
import { PaginationData } from "../types/paginationTypes";
import { ICity, IRegion } from "../types/regionTypes";

interface RegionsResponseData {
    regions: IRegion[];
    order_by: string;
    pagination: PaginationData;
}

interface CitiesResponseData {
    cities: ICity[];
    order_by: string;
    pagination: PaginationData;
}

export async function fetchRegions(): Promise<RegionsResponseData> {
    const url = `${API_DOMAIN}/locations/regions`;
    const params = {
        from: 1,
        to: 50,
    };

    const { data } = await axios.get(url, {
        headers: { "Content-Type": "text/plain" },
        params: params,
    });

    return data;
}

export async function fetchCities(regionId: number): Promise<CitiesResponseData> {
    const url = `${API_DOMAIN}/locations/cities`;
    const params = {
        region_id: regionId,
        order_by: "name",
        from: 1,
        to: 20000,
    };

    const { data } = await axios.get(url, {
        headers: { "Content-Type": "text/plain" },
        params: params,
    });

    return data;
}
