export const PAGE_URL = {
    ABOUT_US: "/about",
    ADMIN: "/admin",
    CHANGE_PASSWORD: "/profile/settings/password",
    CONTACT_US: "/contact-us",
    COOKIES: "/cookies",
    CREATE_LOT: "/create-lot",
    DEALS: "/deals",
    INDEX: "/",
    LOGIN: "/login",
    LOGIN_OLD: "/login-old",
    LOTS: "/lots",
    LOT_CREATE_OFFER: "/lots/:uuid/create-offer",
    LOT_CREATE_SUBS: "/lots/:uuid/create-subs",
    LOT_EDIT_OFFER: "/lots/:uuid/offers/:offer_uuid/edit",
    LOT_ITEM: "/lots/:uuid",
    LOT_ITEM_EDIT: "/lots/:uuid/edit",
    LOT_OFFER_LIST: "/lots/:uuid/offers/:offer_uuid",
    MY_DASHBOARD: "/profile/dashboard",
    MY_DEALS: "/profile/deals",
    MY_DEAL_ITEM: "/profile/deals/:uuid",
    MY_DEPOSITS: "/profile/deposits",
    MY_LOTS: "/profile/lots",
    MY_NOTIFICATIONS: "/profile/notifications",
    MY_OFFERS: "/profile/offers",
    MY_SETTINGS: "/profile/settings",
    PAYMENT: "/payment/:subject/:uuid",
    PAYMENT_ERROR: "/payment/error/:subject/:uuid",
    PAYMENT_SUCCESS: "/payment/success/:subject/:uuid",
    POLICY: "/policy",
    REGISTER: "/register",
    TERMS: "/terms",
};
