import { Button } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import React from "react";
import "./CustomButton.scss";

export interface ICustomButtonProps extends ButtonProps {
    loading?: boolean;
    stretch?: boolean;
    size?: "small" | "medium" | "large";
    btnType?: "white" | "red" | "blue" | "standard";
}

const CustomButton = ({ loading, className, size, btnType, stretch, ...props }: ICustomButtonProps) => {
    let classNames = clsx("custom-btn custom-btn_main", className, {
        "custom-btn_loading": loading,
        "custom-btn_small": size === "small",
        "custom-btn_medium": size === "medium",
        "custom-btn_large": size === "large",
        "custom-btn_white": btnType === "white",
        "custom-btn_red": btnType === "red",
        "custom-btn_blue": btnType === "blue",
    });

    return (
        <Button className={classNames} {...props} style={{ width: stretch ? "100%" : "", ...props.style }}>
            <div className="custom-btn__content">
                <div>{props.children}</div>
                <div className="custom-btn__loading">
                    <CircularProgress style={{ width: "24px", height: "24px" }} />
                </div>
            </div>
        </Button>
    );
};

export default CustomButton;
