import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React from "react";
import BtnBack from "../_HOC/BtnBack/BtnBack";
import CustomButton from "../CustomButton/CustomButton";
import Svg from "../Svg/Svg";
import "./PageBtnBack.scss";

export interface IPageBtnBackProps {
    url?: string;
    onClick?: () => void;
}

const PageBtnBack = ({ url, onClick }: IPageBtnBackProps) => {
    return (
        <BtnBack
            Component={CustomButton}
            startIcon={<Svg i={ArrowBackIosIcon} />}
            className="page__btn-back"
            btnType="white"
            size="small"
            url={url}
            onClick={onClick}
        >
            Назад
        </BtnBack>
    );
};

export default PageBtnBack;
