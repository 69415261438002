import { ILotListState, LotActionType, LotActionTypes } from "../../../types/lotTypes";

const initialState: ILotListState = {
    list: null,
    loading: false,
    error: null,
    pagination: null,
    minMaxPrice: null,
    minMaxCount: null,
};

export const lotsReducer = (state: ILotListState = initialState, action: LotActionType): ILotListState => {
    switch (action.type) {
        case LotActionTypes.FETCH:
            return { ...state, loading: true, error: null };
        case LotActionTypes.FETCH_SUCCESS:
            let { list, pagination, minMaxPrice, minMaxCount } = action.payload;

            return { loading: false, error: null, list, pagination, minMaxPrice, minMaxCount };
        case LotActionTypes.FETCH_ERROR:
            return {
                loading: false,
                error: action.payload,
                list: null,
                pagination: null,
                minMaxCount: null,
                minMaxPrice: null,
            };
        default:
            return state;
    }
};
