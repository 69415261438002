import React from "react";
import { NavLink } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import { PAGE_URL } from "../../../constants/urls";
import { IDeviceState } from "../../../types/deviceTypes";
import { useTypeSelector } from "../../hooks/useTypeSelector";
import CustomButton from "../../components/CustomButton/CustomButton";
import harvester_404 from "../../../assets/images/harvester_404.png";
import "./NotFoundPage.scss";


function NotFoundPage() {
    let {isMobile}: IDeviceState = useTypeSelector((state) => state.device);
console.log('isMobile', isMobile)
    return (
        <MainLayout>
            <div className="not-found page">
                <div className="not-found--gradient"/>
                <div className="not-found__content">
                    <div className="not-found__img">
                        <img src={harvester_404} alt="harvester_404"/>
                    </div>
                    <h1 className={"not-found__h1 " + (isMobile ? 'not-found__h1--mobile' : '')}>Ой, щось пішло не так
                        =/</h1>
                    <h2 className={"not-found__h2 " + (isMobile ? 'not-found__h2--mobile' : '')}>
                        Здається, тюки з пшеницею заважкі. Спробуйте перезавантажити сторінку</h2>
                    <NavLink to={PAGE_URL.INDEX}>
                        <CustomButton size="large">Повернутись на головну сторінку</CustomButton>
                    </NavLink>
                </div>
            </div>
        </MainLayout>
    );
}

export default NotFoundPage;
