import React from "react";
import { IDeviceState } from "../../../types/deviceTypes";
import { useTypeSelector } from "../../hooks/useTypeSelector";

const DesktopView = (props: any) => {
    let { isDesktop }: IDeviceState = useTypeSelector((state) => state.device);

    return isDesktop ? props.children : null;
};

export default DesktopView;
