import { isBrowser, isMobile } from "react-device-detect";
import { DeviceActionTypes, DeviceSwitchAction, IDeviceState } from "../../../types/deviceTypes";

const initialState: IDeviceState = {
    isDesktop: isBrowser, // desktop
    isMobile: isMobile, // mobile or tablet
};

export const deviceReducer = (state: IDeviceState = initialState, action: DeviceSwitchAction): IDeviceState => {
    switch (action.type) {
        case DeviceActionTypes.SWITCH_ON_DESKTOP:
            return { isDesktop: true, isMobile: false };
        case DeviceActionTypes.SWITCH_ON_MOBILE:
            return { isDesktop: false, isMobile: true };
        default:
            return state;
    }
};
