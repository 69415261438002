import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { PAGE_URL } from "../../../../constants/urls";

const BtnBack = ({ url, onClick, Component, ...props }: any) => {
    let history = useHistory();
    let [redirect, setRedirect] = useState<boolean>(false);

    let onBtnClick = () => {
        if (history.length > 2) {
            history.goBack();
        } else {
            setRedirect(true);
        }
    };

    if (redirect) {
        return <Redirect to={url || PAGE_URL.LOTS} />;
    }

    return <Component onClick={onClick || onBtnClick} {...props} />;
};

export default BtnBack;
