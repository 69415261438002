import React from "react";
import { RouteProps } from "react-router";
import { Redirect, Route } from "react-router-dom";
import { PAGE_URL } from "../../../constants/urls";
import { useTypeSelector } from "../../hooks/useTypeSelector";

const PrivateRoute = ({ component, ...props }: RouteProps) => {
    const { user } = useTypeSelector((state) => state.user);
    const isLoggedIn: boolean = !!user;
    const Component: any = component;

    if (user) {
        if (user.reg_step_id === 1 || user.reg_step_id === 2) {
            return <Redirect to={PAGE_URL.REGISTER} />;
        }
    }

    return (
        <Route
            {...props}
            render={(props) => {
                return isLoggedIn ? <Component {...props} /> : <Redirect to={PAGE_URL.LOGIN} />;
            }}
        />
    );
};

export default PrivateRoute;
