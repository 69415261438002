import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import { CURRENCY_ID } from "../../../constants/main";
import { PAGE_URL } from "../../../constants/urls";
import { IUserState, UserSubjectType } from "../../../types/userTypes";
import { logoutUser } from "../../actions/authActions";
import { switchMobileMenu } from "../../actions/mobileMenuActions";
import { useTypeSelector } from "../../hooks/useTypeSelector";
import DesktopView from "../DesktopView/DesktopView";
import MobileMenu from "../MobileMenu/MobileMenu";
import MobileView from "../MobileView/MobileView";
import Svg from "../Svg/Svg";
import "./Header.scss";

export interface IHistoryState {
    isOpenMobileMenu: boolean;
}

const getInitialName = (fullName: string): string => {
    const splitNames = fullName.split(" ");
    let result = `${splitNames[0]} ${splitNames[1][0].toUpperCase()}.`;

    if (splitNames[2][0]) {
        result += `${splitNames[2][0]?.toUpperCase()}.`;
    }

    return result;
};

const Header: React.FC = () => {
    // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const { data } = useTypeSelector((state) => state.exchangeRates);
    const mobileMenu = useTypeSelector((state) => state.mobileMenu);
    const { user }: IUserState = useTypeSelector((state) => state.user);
    const onBurgerClick = () => {
        dispatch(switchMobileMenu(mobileMenu.isOpen));
    };

    const onLogoutClick = () => {
        dispatch(logoutUser());
        history.push(PAGE_URL.LOGIN);
    };

    const UserBlock = (
        <NavLink to={PAGE_URL.MY_DASHBOARD} className="m-header__user-link">
            <Svg i={AccountCircleOutlinedIcon} />
            <span>{user && getInitialName(`${user.last_name} ${user.first_name} ${user.second_name}`)}</span>
        </NavLink>
    );

    const AuthBlock = (
        <NavLink to={PAGE_URL.LOGIN} activeClassName="active" className="m-header__user-link">
            <Svg i={AccountCircleOutlinedIcon} />
            <span>Увійти</span>
        </NavLink>
    );

    return (
        <>
            <DesktopView>
                <div className="header">
                    <div className="header__content content">
                        <div className="header__logo">
                            <NavLink to={PAGE_URL.INDEX}>
                                <img src={logo} alt="logotype" />
                            </NavLink>
                        </div>
                        <div className="header__nav">
                            <NavLink to={PAGE_URL.LOTS} activeClassName="active" className="header__nav-link">
                                Торги
                            </NavLink>
                            <NavLink to={PAGE_URL.DEALS} activeClassName="active" className="header__nav-link">
                                Контракти
                            </NavLink>
                            <NavLink to={PAGE_URL.CREATE_LOT} activeClassName="active" className="header__nav-link">
                                Створити лот
                            </NavLink>
                        </div>
                        {data && (
                            <div className="header__currency">
                                $ {parseFloat(String(data[CURRENCY_ID.USD])).toFixed(2)} <span>/</span> €{" "}
                                {parseFloat(String(data[CURRENCY_ID.EUR])).toFixed(2)}
                            </div>
                        )}
                        {user && (
                            <>
                                {/*<div className="header__notification">*/}
                                {/*    <div className="header__notification-svg">*/}
                                {/*        <NotificationsNoneIcon />*/}

                                {/*        <div className="header__notification-svg-num">5</div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="header__user">
                                    <NavLink to={PAGE_URL.MY_DASHBOARD} className="header__user-link">
                                        <div className="header__user-link-icon">
                                            <Svg i={AccountCircleOutlinedIcon} />
                                        </div>
                                        {user.customer && (
                                            <div className="header__user-link-text">
                                                {user?.subject_type === UserSubjectType.YUR && (
                                                    <>
                                                        <div className="header__user-link-text-name">
                                                            {user.customer.name}
                                                        </div>
                                                        <div className="header__user-link-text-inn">
                                                            {getInitialName(
                                                                `${user.last_name} ${user.first_name} ${user.second_name}`,
                                                            )}
                                                        </div>
                                                    </>
                                                )}

                                                {(user?.subject_type === UserSubjectType.FIZ ||
                                                    user?.subject_type === UserSubjectType.FOP) && (
                                                    <>
                                                        <div className="header__user-link-text-name size-large">
                                                            {getInitialName(user.customer.name)}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </NavLink>

                                    <div className="header__user-menu">
                                        <div className="header__user-menu-list">
                                            <NavLink to={PAGE_URL.MY_DASHBOARD} className="header__user-menu-list-item">
                                                Центр управління
                                            </NavLink>
                                            {/*<NavLink to={PAGE_URL.MY_SETTINGS} className="header__user-menu-list-item">*/}
                                            {/*    Налаштування*/}
                                            {/*</NavLink>*/}
                                            <div onClick={onLogoutClick} className="header__user-menu-list-item">
                                                Вихід
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {!user && (
                            <NavLink to={PAGE_URL.LOGIN} activeClassName="active" className="header__user-non-auth">
                                <Svg i={AccountCircleOutlinedIcon} />
                                Увійти
                            </NavLink>
                        )}
                    </div>
                </div>
            </DesktopView>
            <MobileView>
                <div className="m-header">
                    <div className="m-header__burger" onClick={onBurgerClick}>
                        <MenuIcon />
                    </div>
                    <div className="m-header__logo">
                        <NavLink to={PAGE_URL.INDEX}>
                            <img src={logo} alt="logotype" />
                        </NavLink>
                    </div>
                    <div className="m-header__user">{user ? UserBlock : AuthBlock}</div>
                </div>

                <MobileMenu />
            </MobileView>
        </>
    );
};

export default Header;
