import axios, { AxiosRequestConfig } from "axios";
import { API_DOMAIN, API_PATH } from "../constants/api";
import { ILoginUserResponse } from "../types/userTypes";
import { getAuthData, removeAuthData, setAuthData } from "../utils/localStorage";

let isPause: boolean = false;
let isRefreshing: boolean = false;
let failedQueue: any[] = [];

let processFailedQueue = (token: string, error?: any) => {
    failedQueue.forEach((prom) => {
        if (token) {
            prom.resolve(token);
        } else {
            prom.reject(error);
        }
    });

    failedQueue = [];
};

axios.interceptors.request.use(
    (request) => {
        let authData = getAuthData();
        let regExpApiDomain = new RegExp("^" + API_DOMAIN);

        if (authData?.token && regExpApiDomain.test(<string>request.url)) {
            request.headers["Authorization"] = "Bearer " + authData.token;
        }

        return request;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(
    (response) => response,
    (err) => {
        if (!isPause && (err?.response?.status === 401 || err?.response?.status === 403)) {
            const { refresh_token } = getAuthData() || {};
            const originalRequest: AxiosRequestConfig = err.config;

            if (refresh_token) {
                if (isRefreshing) {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({ resolve, reject });
                    })
                        .then((newToken) => {
                            originalRequest.headers["Authorization"] = "Bearer " + newToken;
                            return axios(originalRequest);
                        })
                        .catch((err) => {
                            return Promise.reject(err);
                        });
                } else {
                    isRefreshing = true;

                    return new Promise((resolve, reject) => {
                        axios.post(`${API_DOMAIN}${API_PATH.REFRESH_TOKEN}`, { refresh_token }).then(
                            (response) => {
                                let newAuthData: ILoginUserResponse = response.data;

                                if (newAuthData?.token) {
                                    setAuthData(newAuthData);
                                    isRefreshing = false;
                                    isPause = true;

                                    setTimeout(() => {
                                        isPause = false;
                                    }, 1000 * 60);

                                    originalRequest.headers["Authorization"] = "Bearer " + newAuthData.token;

                                    setTimeout(() => {
                                        processFailedQueue(newAuthData.token);
                                    }, 1);

                                    axios(originalRequest).then(resolve, reject);
                                } else {
                                    removeAuthData();
                                    reject();
                                }
                            },
                            (error) => {
                                return reject(error);
                            },
                        );
                    });
                }
            }
        }

        return Promise.reject(err);
    },
);
