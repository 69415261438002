import { Dispatch } from "redux";
import { fetchCategoryList } from "../../services/categoryService";
import { ICategory } from "../../types/categoryTypes";
import { CategoryActionTypes } from "../store/reducers/categoriesReducer";

export const fetchCategoryListAction = () => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({ type: CategoryActionTypes.FETCH });

            const response = await fetchCategoryList();
            const list: ICategory[] = response.data;

            dispatch({ type: CategoryActionTypes.FETCH_SUCCESS, payload: list });
        } catch (e) {
            dispatch({ type: CategoryActionTypes.FETCH_ERROR, payload: "Ошибка при загрузке списка категорий" });
        }
    };
};
