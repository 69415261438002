import { DEFAULT_ROWS_PER_PAGE } from "../constants/admin";
import { ILoginUserResponse, IUser } from "../types/userTypes";

const USER_DATA = "USER_DATA";
const ADMIN_ROWS_PER_PAGE = "ADMIN_ROWS_PER_PAGE";

export const setAuthData = (authData: ILoginUserResponse) => {
    localStorage.setItem(USER_DATA, JSON.stringify(authData));
};

export const getAuthData = (): ILoginUserResponse | null => {
    let str: string | null = localStorage.getItem(USER_DATA);

    return str ? JSON.parse(str) : null;
};

export const removeAuthData = () => {
    localStorage.removeItem(USER_DATA);
};

export const getUserData = (): IUser | null => {
    let userData: IUser | null = null;

    try {
        let authData: ILoginUserResponse | null = getAuthData();
        if (authData) userData = JSON.parse(atob(authData.session_data));
    } catch (e) {
        //
    }

    return userData;
};

export const setAdminRowsPerPage = (number: number) => {
    localStorage.setItem(ADMIN_ROWS_PER_PAGE, number.toString());
};

export const getAdminRowsPerPage = (): number => {
    let value: string | null = localStorage.getItem(ADMIN_ROWS_PER_PAGE);

    return value ? parseInt(value) : DEFAULT_ROWS_PER_PAGE;
};
