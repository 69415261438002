import axios, { AxiosResponse } from "axios";
import { API_DOMAIN, API_PATH } from "../constants/api";
import { ILot } from "../types/lotTypes";
import { ICreateOfferParams, IOffer, IOfferResponse, OfferStatusID } from "../types/offerTypes";
import { IPaginationProps } from "../types/paginationTypes";

export const fetchProfileOffers = (params: IPaginationProps): Promise<AxiosResponse<IOfferResponse>> => {
    // here goes from, to
    return axios.get(API_DOMAIN + API_PATH.PROFILE_OFFERS, {
        headers: { "Content-Type": "text/plain" },
        params: params,
    });
};

export const fetchOffersByLotUUID = (lotUUID: string): Promise<AxiosResponse<IOfferResponse>> => {
    return axios.get(API_DOMAIN + API_PATH.LOT_OFFERS + `/${lotUUID}`);
};

export const fetchOfferByUUID = (uuid: string): Promise<AxiosResponse<IOffer>> => {
    return axios.get(API_DOMAIN + API_PATH.OFFERS + `/${uuid}`);
};

export const createOfferByLotUUID = (offerData: ICreateOfferParams): Promise<AxiosResponse<IOffer>> => {
    return axios.post(API_DOMAIN + API_PATH.OFFERS, offerData);
};

export const editOfferByUUID = (offerUUID: string, offerData: ICreateOfferParams): Promise<AxiosResponse<IOffer>> => {
    return axios.put(API_DOMAIN + API_PATH.OFFERS + `/${offerUUID}`, offerData);
};

export const acceptOfferById = (
    offerUUID: number | string,
): Promise<AxiosResponse<{ offer_status_id: OfferStatusID; uuid: string; deal_uuid: string }>> => {
    return axios.post(API_DOMAIN + API_PATH.OFFERS_ACCEPT, { offer_uuid: offerUUID });
};

export const rejectOfferById = (offerUUID: number | string, comment: string = ""): Promise<AxiosResponse<any>> => {
    return axios.post(API_DOMAIN + API_PATH.OFFERS_REJECT, { offer_uuid: offerUUID, comment });
};

export const readOfferByUUID = (offerUUID: string): Promise<AxiosResponse<{ new_offer: boolean; uuid: string }>> => {
    return axios.put(API_DOMAIN + API_PATH.OFFER_READ.replace(":uuid", offerUUID));
};

export const cancelOffer = (offerUUID: string): Promise<AxiosResponse<any>> => {
    return axios.put(`${API_DOMAIN}${API_PATH.OFFER_CANCEL}`.replace(":uuid", offerUUID));
};

export const publishOffer = (offerUUID: string): Promise<AxiosResponse<ILot>> => {
    return axios.post(API_DOMAIN + API_PATH.OFFER_PUBLISH.replace(":uuid", offerUUID));
};
