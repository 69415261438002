export interface IExchangeRange {
    [key: number]: number;
}

export enum ExchangeRangeActionTypes {
    FETCH,
    FETCH_SUCCESS,
    FETCH_ERROR,
}

export interface IRegionFetchAction {
    type: ExchangeRangeActionTypes.FETCH;
}

export interface IRegionFetchSuccessAction {
    type: ExchangeRangeActionTypes.FETCH_SUCCESS;
    payload: IExchangeRange;
}

export interface IRegionFetchErrorAction {
    type: ExchangeRangeActionTypes.FETCH_ERROR;
    payload: string;
}

export type ExchangeRangeType = IRegionFetchAction | IRegionFetchSuccessAction | IRegionFetchErrorAction;

export interface IExchangeRangeState {
    data: IExchangeRange | undefined;
    loading: boolean;
    error: null | string;
}

const initialState: IExchangeRangeState = {
    data: undefined,
    loading: false,
    error: null,
};

export const exchangeRatesReducer = (
    state: IExchangeRangeState = initialState,
    action: ExchangeRangeType,
): IExchangeRangeState => {
    switch (action.type) {
        case ExchangeRangeActionTypes.FETCH:
            return { ...state, loading: true, error: null };
        case ExchangeRangeActionTypes.FETCH_SUCCESS:
            return { loading: false, error: null, data: action.payload };
        case ExchangeRangeActionTypes.FETCH_ERROR:
            return { loading: false, error: action.payload, data: undefined };
        default:
            return state;
    }
};
