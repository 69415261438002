import { ICustomer } from "./customerTypes";
import { IDeal } from "./dealTypes";
import { ICurrency, IDeliveryMethod, IECPSign, IMinMax, IPagination, IResponse, IVisibility } from "./mainTypes";
import { IOfferSimple } from "./offerTypes";
import { ICity, IRegion } from "./regionTypes";

export enum LotType {
    SELL = 1,
    BUY = 2,
}

export enum LotStatus {
    DRAFT = 1,
    PUBLISHED = 2,
    CANCELED = 3,
    EXPIRED = 4,
    DONE = 5,
}

export interface ILotSimple {
    add_information: string;
    category_id: number;
    city: ICity;
    count: number;
    creation_deposit_sum: number;
    customer?: ICustomer;
    customer_rate: number;
    customer_winner?: ICustomer;
    currency: ICurrency;
    deal?: IDeal;
    end_at: number;
    final_at?: number;
    id: number;
    is_participant: boolean;
    lot_fields_array: { data: any; value: number | boolean; field_id: number; value_id: number }[];
    lot_number: number;
    lot_status_id: LotStatus;
    lot_type_id: LotType;
    price: number;
    region: IRegion;
    shipment_locations: any[];
    shipment_methods: IDeliveryMethod[];
    shipment_place: string;
    sum: number;
    uuid: string;
    vendor_code: string | null;
    views_count: number;
    visibility: IVisibility;
    elevator_bandwidth?: string;
    shipment_code?: string;
}

export interface ILot extends ILotSimple {
    offers?: IOfferSimple[];
    offers_count: number;
}

export interface ILotsResponse extends IResponse {
    lots: ILot[];
    count: IMinMax;
    prices: IMinMax;
}

export enum LotActionTypes {
    FETCH = "FETCH_LOT",
    FETCH_SUCCESS = "FETCH_LOT_SUCCESS",
    FETCH_ERROR = "FETCH_LOT_ERROR",
}

export interface ILotFetchAction {
    type: LotActionTypes.FETCH;
}

export interface ILotFetchSuccessAction {
    type: LotActionTypes.FETCH_SUCCESS;
    payload: { list: ILot[]; pagination: IPagination; minMaxPrice: IMinMax; minMaxCount: IMinMax };
}

export interface ILotFetchErrorAction {
    type: LotActionTypes.FETCH_ERROR;
    payload: string;
}

export type LotActionType = ILotFetchAction | ILotFetchSuccessAction | ILotFetchErrorAction;

export interface ILotListState {
    list: null | ILot[];
    loading: boolean;
    error: null | string;
    pagination: null | IPagination;
    minMaxPrice: null | IMinMax;
    minMaxCount: null | IMinMax;
}

export interface IFetchLotListProps {
    from?: string | number;
    to?: string | number;
    order?: string | number;
    order_by?: string;
    lot_type_id?: string;
    lot_status_id?: string;
    category_id?: string;
    regions?: string;
    min_price?: string;
    max_price?: string;
    min_count?: string;
    max_count?: string;
}

export interface ICreateLotParams {
    lot_type_id: string | number;
    category_id: string | number;
    currency_id: number | null;
    price: number;
    count: number;
    end_at: number;
    add_information: string | number;
    region_id: number;
    city_id: number | null;
    lot_fields_array: any[];
    shipment_methods: number[];
    shipment_place: string;
    shipment_code: string;
    elevator_bandwidth: string;
    visibility_id: number;
    consent_content?: string | null;
    consent_sign?: IECPSign | null;
}
