export const ERROR_MESSAGES = {
    required: "Обов'язкове поле",
    email: "Некоректний email",
};

export enum ErrorCode {
    EMAIL_EXISTS = 4446,
    PHONE_EXISTS = 4447,
    INVALID_ECP_KEY = 3964,
}
